import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CartProperties,
  CartItemProperties,
  CustomerProperties,
} from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  SpanTypes,
  TraceService,
  CookieService,
  CookiePromoCode,
} from '@sidkik/global';
import { CartFacade } from '../../../+state/cart/cart.facade';
import { NotificationService } from '@sidkik/ui';
import { DOCUMENT } from '@angular/common';

interface DiscountForm {
  code: FormControl<string>;
}

@Component({
  selector: 'sidkik-discount-form',
  templateUrl: './discount-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountFormComponent implements OnInit {
  @Input() cartItems: (Partial<CartItemProperties> | undefined)[] | null = [];
  @Input() me: CustomerProperties | null | undefined;
  @Output() discountRequested: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup<DiscountForm>;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private traceService: TraceService,
    private cartFacade: CartFacade,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationService,
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService
  ) {}

  async ngOnInit(): Promise<void> {
    const promoCode = this.cookieService.get(CookiePromoCode);
    this.form = this.formBuilder.nonNullable.group({
      code: [promoCode ?? '', [Validators.required]],
    });
    if (promoCode) {
      await this.checkCode();
    }
  }

  async checkCode() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      const formValue = { ...this.form.value };
      this.traceService.startSpan(SpanTypes.shopVerifyCoupon);
      const traceHeader = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.shopVerifyCoupon
      );
      if (formValue.code) {
        try {
          const cart: Partial<CartProperties> = {
            data: {
              items: this.cartItems as CartItemProperties[],
            },
          };

          const checkResponse = await this.cartFacade.checkDiscountCode(
            formValue.code,
            cart as CartProperties,
            traceHeader
          );
          if (!checkResponse || !checkResponse.valid) {
            setTimeout(() =>
              this.notificationsService.showError(
                'Invalid Coupon',
                'The coupon you entered is invalid'
              )
            );
          }
          this.traceService.endSpan(SpanTypes.shopVerifyCoupon);
        } catch (err: any) {
          if (err && err.responseError) {
            if (err.responseError.status === 401) {
              setTimeout(() =>
                this.notificationsService.showError(
                  'Coupon Issue',
                  'Unable to verify coupon at this time. Will refresh page to allow to try again.'
                )
              );
              setTimeout(() => {
                this.document.location.reload();
              }, 3000);
            } else {
              setTimeout(() =>
                this.notificationsService.showError(
                  'Coupon Issue',
                  'Unable to verify coupon at this time.'
                )
              );
            }
          }
          this.traceService.endSpan(SpanTypes.shopVerifyCoupon, err as Error);
        }
      }
    } else {
      // this.notificationsService.showError(
      //   'Coupon Issue',
      //   'Unable to verify coupon at this time.'
      // );
    }
  }
}
